.offer-block{
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1050px;
  @media ($bp-larger-than-desktop) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &__img{
    width: 100%;
    max-width: 100%;
  }
}
.offer-list{
  max-width: 976px;
  margin-left: auto;
  margin-right: auto;
  &__caption{
    text-align: center;
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-size: 24px;
    margin-bottom: 30px;
    @media ($bp-larger-than-desktop) {
      font-size: 36px;
      margin-bottom: 50px;
    }
  }
  &__text{
    padding: 30px;
    background-color: #efefef;
    margin-bottom: 25px;
    line-height: 130%;
    @media ($bp-larger-than-tablet) {
      padding: 30px;
      line-height: 230%;
    }
    p{
      font-size: 14px;
      font-weight: bold;
      font-family: Arial, sans-serif;
      margin: 0;
      @media ($bp-larger-than-tablet) {
        font-size: 24px;
      }
    }
  }
}
.offer-btn{
  width: 100%;
  background-color: $orange;
  text-align: center;
  height: 70px;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
  font-family: Arial, sans-serif;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  border: 1px solid #b48956;
  line-height: 120%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ($bp-larger-than-desktop) {
    font-size: 30px;
    height: 120px;
    width: 526px;
  }
  &:hover{
    color: #fff;
    opacity: 0.9;
  }
}

#leadform{
  padding: 50px;
  max-width: 900px;
  background-color: #e9e9e9;
}